import {useStaticQuery, graphql} from 'gatsby';


export const GetBlogPageEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query BlogPageEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "BlogPage"}}}
		) {
		  edges {
			node {
			  frontmatter {
          blogPageTitle
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};