import React, {useContext} from 'react';
import Layout from '../components/template/layout';
import LangContext from '../components/context/langContext';
import { GetBlogPageFR } from '../content/pages/blogPage/content/fr/GetBlogPage';
import { GetBlogPageEN } from '../content/pages/blogPage/content/en/GetBlogPage';
import ArticleTeasers from '../components/articles/articles-teaser';
import { GetBlogPageSeoFR } from '../content/pages/blogPage/seo/fr/getBlogSeo';
import { GetBlogPageSeoEN } from '../content/pages/blogPage/seo/en/getBlogSeo';

const Blog = () => {
    const ctx = useContext(LangContext)
    const content = ctx.lang === 'fr' ? GetBlogPageFR() : GetBlogPageEN();
    const seo =  ctx.lang === 'fr' ? GetBlogPageSeoFR() : GetBlogPageSeoEN()
    return (
        <Layout
            title={seo.pageTitle}
            description={seo.pageDescription}
            lang={ctx.lang}
            page="blog"
        >
            <h2
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >{content.blogPageTitle}</h2>
            <ArticleTeasers 
                lang={ctx.lang}
            />
        </Layout>
    );
};

export default Blog;